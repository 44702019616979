import React, { Fragment, useState } from "react";
import "../styles/layout.css";
import "../styles/grades.css";

export default function Grades(props) {
  const [selectedYear, setSelectedYear] = useState(2023);
  return (
    <div className="container" style={{ overflowX: "auto" }}>
      <h1 className="head1">Notes et Résultats</h1>
      <table>
        <thead>
          <tr>
            <th rowSpan="2">N</th>
            <th rowSpan="2">module</th>
            <th colSpan="2">ordinaire</th>
            <th colSpan="2">rattrapage</th>
            <th rowSpan="2">
              années
              <select
                style={{
                  backgroundColor: "#637e9e",
                  color: "#fff",
                }}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                }}
                value={selectedYear}
              >
                <option defaultValue={2023} value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
              </select>
            </th>
          </tr>
          <tr>
            <th>note</th>
            <th>résultat</th>
            <th>note</th>
            <th>résultat</th>
          </tr>
        </thead>
        <tbody>
          <GradesRow selectedYear={selectedYear} grades={props.grades} />
        </tbody>
      </table>
    </div>
  );
}

function GradesRow(props) {
  console.log( props.selectedYear);

  if (props.grades === undefined) return "";


  return props.grades.map((years) => {
    return Object.values(years).map((year) => {
      return Object.values(year).map((semesters) => {
        console.log(semesters)
        // return Object.values(semesters).map((grade) => {
        var session1 = 0;
        var session2 = 1;
        if (semesters.length > 1 && semesters[1].session === "1") {
          session1 = 1;
          session2 = 0;
        }
        return (
          <tr
            key={semesters[0].elp_id}
            className={
              (semesters[0].nature !== "MOD" ? "semester-row" : "") +
              (semesters[0].year != props.selectedYear ? " hidden" : "")
            }
          >
            <td>{semesters[0].abv}</td>
            <td
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{width:"auto",textAlign:"left"}}>{semesters[0].lib_elp}</span>
              {semesters[0].lib_elp_arb === "" ||
              semesters[0].lib_elp_arb === "..." ? (
                ""
              ) : (
                <span> {semesters[0].lib_elp_arb}</span>
              )}
            </td>
            {renderValidateResult(semesters,session1)}
            {semesters.length > 1 ? (
              <Fragment>
                {renderValidateResult(semesters,session2)}
              </Fragment>
            ) : (
              <Fragment>
                <td></td>
                <td></td>
              </Fragment>
            )}
            <td>{semesters[0].year}</td>
          </tr>
        );
        // });
      });
    });
  });
}

function renderValidateResult(semesters, session1) {
  return (
      <>
        <td>{semesters[session1].result_label !== "DEF" ? semesters[session1].result : ''}</td>
        <td>{semesters[session1].result_label !== "DEF" ? semesters[session1].result_label : ''}</td>
      </>
  )
}
