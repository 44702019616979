// https://api.flshbm.ma/storage/schedule/LBFEA/S1/G1

import React, { Fragment } from "react";
import "../styles/layout.css";
import { Button } from "semantic-ui-react";
export default function Schedule(props) {
  // var element = props.semesters;
  // if (element != undefined)
  //   var link =
  //      +
  //     props.diploma +
  //     "/S" +
  //     element.elp.nature.substring(3, 4) +
  //     "/G" +
  //     element.group.substring(2, 3) +
  //     ".pdf";
  return (
    <div className="container">
      {props.semesters.map((semester) => {
        let link = "https://web.flshbm.ma/static/schedule/" + props.diploma + "/S" +  semester.elp.abv.substring(2,6) + "/G" + semester.group.substring(2,3) + ".pdf";
        return (
          <Fragment key={semester.elp_id}>
            <h1 className="head1">Emplois du Temps: {semester.elp.name}</h1>
            <a
              style={{
                textAlign: "center",
                backgroundColor: "#e0e1e2",
                color: "#495057",
                padding: "12px 30px",
                margin: "8px",
                border: "1px solid #ebebeb",
                display: "block",
                borderRadius: "4px",
                fontSize: "18px",
                fontWeight: "600",
              }}
              href={link}
              download
              target="_blank"
            >
              Télécharger l'emploi
            </a>
            <iframe src={link} width="100%" height="600px" />
          </Fragment>
        );
      })}
    </div>
  );
}
