import React from "react";
import styles from "../styles/student.module.css";
import  "../styles/layout.css";
export default function Elements(props) {
  return (
    <div className="container">
      <h1 className="head1">Modules Inscrits</h1>

      {props.elements.map((element) => {
        return (
          <div key={element.elp_id} className={styles.subjectList}>
            <div
              className={
                element.elp.nature.includes("SM")
                  ? styles.listNumber + " " + styles.listNumberSem
                  : styles.listNumber
              }
            >
              {element.elp.abv}
            </div>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"80%",margin:"auto"}}>
              <div className={styles.listTitle}>{element.elp.name}</div>
              <div
                style={{ textAlign:"right" }}
                className={styles.listTitle}
              >
                {element.elp.name_ar}
              </div>
            </div>
            <div style={{ marginLeft: "auto" }} className={styles.listGroup}>
              {element.group}
            </div>
          </div>
        );
      })}
    </div>
  );
}
