import React, {useEffect, useState} from "react";
import {
    Button,
    Confirm, Dropdown,
    Grid,
    Icon,
    Input,
    Select
} from "semantic-ui-react";
import "../styles/layout.css";
import "../styles/attestations-module.css";

export default function Attestation(props) {

    const [isOpened, setIsOpened] = useState(false);
    const [attestations, setAttestations] = useState([]);
    const [loading, setLoading] = useState(false);

    const [confirm, setConfirm] = useState(false);
    const [type, setType] = useState(null);

    const titles = {
        insc: "Attestation D'inscription",

        res_1: "Attestation réussite S1",
        res_2: "Attestation réussite S2",
        res_3: "Attestation réussite S3",
        res_4: "Attestation réussite S4",

        rv_a1: "Relevé de notes année 1",
        rv_a2: "Relevé de notes année 2",
        rv_a3: "Relevé de notes année 3",

        rv_s1: "Relevé de notes semester 1",
        rv_s2: "Relevé de notes semester 2",
        rv_s3: "Relevé de notes semester 3",
        rv_s4: "Relevé de notes semester 4",
        rv_s5: "Relevé de notes semester 5",
        rv_s6: "Relevé de notes semester 6",

        deug: "Attestation DEUG",
        licence: "Attestation Licence",
        etp1: "Attestation de réussite S1-S2",
    }

    const [requests, setRequests] = useState([]);
    const [canRequest, setCanRequest] = useState({});
    const [filier, setFilier] = useState(null);

    function toggle() {
        setIsOpened(!isOpened);
    }

    async function fetchAttestations(apogee) {
        setLoading(true);
        const response = await fetch(
            "https://api.flshbm.ma/v1/me/requests-v2/" + apogee
        );

        let data = await response.json();
        const {requests, canRequest, filier} = data.data;
        console.log("student data request :")
        console.log(data.data);
        setLoading(false);
        setRequests(requests);
        setCanRequest(canRequest);
        setFilier(filier)
        return setAttestations(data.data.requests.data ? data.data.requests.data : []);
    }

    async function submitAttestationRequest({apo, type, branch}) {
        setLoading(true);
        const req = {
            apogee: apo,
            branch: branch,
            type: type
        };
        const apiUrl = "https://api.flshbm.ma/v1";
        const myHeaders = new Headers();
        const formData = new FormData();
        formData.append("id", req.apogee);
        formData.append("branch", req.branch);
        formData.append("type", req.type);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
        };

        await fetch(
            apiUrl + "/requests-v2/attestation",
            requestOptions
        ).then((response) => {
            setLoading(false);
            setConfirm(false);
            setIsOpened(false);
            setType(null)
            fetchAttestations(req.apogee);
        });
    }

    useEffect(async () => {
        await fetchAttestations(props.profile.id);
    }, []);

    return (
        <div className="container attestation">
            {loading ? (
                <div className="ui active centered inline loader"/>
            ) : null}

            <div>
                <h1 className="head1">Mes Demande <span>
                    <Button
                        onClick={toggle}
                        floated="right"
                        icon
                        labelPosition="left"
                        primary
                        size="small"
                    >
                                            <Icon name="user"/> Nouvelle demande
                                        </Button>
                </span></h1>
                <table celled>
                    <thead>
                    <tr>
                        <th scope="col">APOGEE</th>
                        <th scope="col">Filière</th>
                        <th scope="col">Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Etat</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>

                    {requests && (
                        <tbody>
                        {requests.map((request, index) => (

                            <tr key={index}>
                                <td data-label="APOGEE">{request.apogee}</td>
                                <td data-label="Filière">{request.branch}</td>
                                <td data-label="Type">{titles[request.type]}</td>
                                <td data-label="Date">{(new Date(request.date)).toLocaleDateString()}</td>

                                <td data-label="Etat">{request.status === 'pending' ? 'En cours' : 'Disponible'}</td>
                                <td data-label="Action">
                                    {
                                        (request.status === 'available' && request.meta_data && request.meta_data.link) &&
                                        (
                                            <a href={"//api.flshbm.ma" + request.meta_data.link + "?dl=1"}
                                               target={"_blank"}>Telecharger</a>
                                        )
                                    }

                                    {
                                        (request.status === 'available' && !(request.meta_data && request.meta_data.link)) &&
                                        (
                                            "retirer a partire du scolarité"
                                        )
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    )}

                    {(requests.length <= 0 && !loading) ? (
                        <tbody>Aucune attestation demandée</tbody>
                    ) : null}

                </table>
            </div>

            {DemandAttestation(confirm, isOpened, setConfirm, submitAttestationRequest, canRequest, setType, filier, type, titles, props)}
        </div>
    );
}

function DemandAttestation(confirm, isOpened, setConfirm, submitAttestationRequest, canRequest, setType, filier, type, titles, props) {
    const fil = [
        {key: "--", value: "--", text: "-----"},
        {key: "LBFEA", value: "LBFEA", text: "Etudes Arabes"},
        {key: "LBFES", value: "LBFES", text: "English Studies"},
        {key: "LBFEI", value: "LBFEI", text: "Etudes Islamiques"},
        {key: "LBFEF", value: "LBFEF", text: "Etudes Françaises"},
        {key: "LBFGE", value: "LBFGE", text: "Géographie"},
        {key: "LBFHP", value: "LBFHP", text: "Histoire, Patrimoine et Civilisation"},
        {key: "LBFSO", value: "LBFSO", text: "Sociolologie"},
        {key: "LBLPH", value: "LBLPH", text: "Philosophie"},
        //LBLSO,LBLHP,LBLGE,LBLES,LBLEA,LBLEF,LBLEI,LBLPH

    ];

    const fil_new = [
        {key: "LBLEA", value: "LBLEA", text: "Etudes Arabes"},
        {key: "LBLES", value: "LBLES", text: "English Studies"},
        {key: "LBLEI", value: "LBLEI", text: "Etudes Islamiques"},
        {key: "LBLEF", value: "LBLEF", text: "Etudes Françaises"},
        {key: "LBLGE", value: "LBLGE", text: "Géographie"},
        {key: "LBLHP", value: "LBLHP", text: "Histoire, Patrimoine et Civilisation"},
        {key: "LBLSO", value: "LBLSO", text: "Sociolologie"},
        {key: "LBLPH", value: "LBLPH", text: "Philosophie"},
    ];

    const [myFilier, setMyFilier] = useState(null);

    function isTextValid(text) {
        // Check if the text starts with "23" and has exactly 8 characters
        return /^23\d{6}$/.test(text);
    }

    return (
        <>
            {!isOpened ? null : (

                <div style={{hidden: isOpened, marginTop: 30}}>
                    <h1 className="head1">Demandé votre attestation</h1>
                    <div>
                        <Grid divided="vertically">
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <Input
                                        label="APOGEE"
                                        value={props.profile.id}
                                        placeholder="APOGEE"
                                        name="apogee"
                                        disabled={true}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    {props.diploma ? (
                                        <Select
                                            style={{marginLeft: "20px"}}
                                            name="branch"
                                            value={props.diploma}
                                            label="Veuillez selectioner votre filière"
                                            placeholder="Veuillez selectioner votre filière"
                                            options={isTextValid(props.profile.id) ? fil_new : fil}
                                            disabled={true}
                                        />
                                    ) : (
                                        <Select
                                            style={{marginLeft: "20px"}}
                                            name="branch"
                                            label="Veuillez selectioner votre filière"
                                            placeholder="Veuillez selectioner votre filière"
                                            options={isTextValid(props.profile.id) ? fil_new : fil}
                                            onChange={(val, data) => setMyFilier(data.value)}
                                        />
                                    )}

                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </div>

                    <br/>
                    <br/>

                    <p className={"hintText"}>
                        Liste des document à demandé
                        <p>(Cliquez pour demander que vous souhaitez)</p>
                    </p>

                    <div>
                        <Grid>
                            <Grid.Row className={"attestationCardContainer"}>

                                {
                                    canRequest && Object.keys(canRequest).map((key) => {
                                        return (
                                            <div key={key} className={"attestationCard"}>
                                                <span onClick={() => {
                                                    setType(key);
                                                    setConfirm(true)
                                                }}>
                                                    {canRequest[key].name}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>

            )}
            {
                (filier || props.diploma || myFilier) &&
                type && <Confirm
                    open={confirm}
                    header={'Demande attestation: ' + titles[type]}
                    content='Proced to demande attestation'
                    onCancel={() => setConfirm(false)}
                    onConfirm={() => submitAttestationRequest({
                        apo: props.profile.id,
                        branch: filier ? filier : props.diploma ? props.diploma : myFilier,
                        type: type
                    })}
                />
            }
        </>
    )
}
