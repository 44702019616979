import "./App.css";
import PortalPage from "./pages/PortalPage";
import LoginPage from "./pages/LoginPage";
import {BrowserRouter,Switch, Route } from "react-router-dom";

function App() {

  
  return (
      <BrowserRouter>
      <Switch>
        <Route exact path="/login"  component={LoginPage} />
        <Route path="/" component={PortalPage} />
      </Switch>
      </BrowserRouter>
  );
}

export default App;
