import React from "react";
import "../styles/ui.css";
export function Alert(props) {
  if (!props.enabled) {
    return "";
  }
  return (
    <div className={`alert ${props.type}`} >
      <div className="message">{props.message}</div>
      {/* <div className="alert-body">{props.detail}</div> */}
    </div>
  );
}

export function Toast(props) {
  return "";
}
