import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const loginUser = createAsyncThunk(
  "users/login",
  async ({ apogee, cin, birthDate }, thunkAPI) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("origin", "https://api.flshbm.ma");
      //myHeaders.append("origin", "http://system-api.test");
      var formdata = new FormData();
      formdata.append("apo", apogee);
      formdata.append("cin", cin);
      formdata.append("dn", birthDate);
      // formdata.append("apo",);
      // formdata.append("cin","");
      // formdata.append("dn","01/01/2001");
      formdata.append("session", "sp");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      const response = await fetch(
        "https://api.flshbm.ma/v1/student/results/v1",
        //"http://system-api.test/v1/student/results/v1",
        requestOptions
      );
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem("apo", data.data.student.id);
        localStorage.setItem("cin", data.data.student.et_cin);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      // thunkAPI.rejectWithValue(e.response.data)
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogged: false,
    isFetching: false,
    isRejected: false,
    semesters: [],
    profile: {},
    elements: [],
    results: [],
    diploma: "",
    attestations: [],
    email: null
  },
  reducers: {
    logout: (state) => {
      state.isLogged = false;
      state.isFetching = false;
      state.isRejected = false;
      state.semesters = [];
      state.profile = {};
      state.elements = [];
      state.results = [];
      state.diploma = "";
      state.attestations = [];
      state.email = null;
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isRejected = false;
      state.isLogged = true;
      state.elements = Object.values(payload.data.ip);
      state.profile = payload.data.student;
      state.semesters = Object.values(payload.data.semesters);
      state.diploma = payload.data.diplome;
      state.diploma = payload.data.diplome;
      state.grades = Object.values(payload.data.results);
      state.email = payload.data.email
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isRejected = true;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
