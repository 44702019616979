import React, {useState} from "react";
import {Button, Checkbox, Form, Grid, Label} from "semantic-ui-react";
import styles from "../styles/student.module.css";
import "../styles/layout.css";
import profileImage from "../profile.png";

export default function Profile(props) {
    const profile = props.profile;
    const email = props.email;

    const [showPass, setShowPass] = useState(false);
    return (
        <div className="container">
            <h1 className="head1">Profile d'étudiant(e)</h1>

            <div className="profile-data row">
                <div className="profile-data-perso col-md-6">
                    {/* <img src={profileImage} width="160px" height="160px" /> */}
                    <div className={styles.profileTag}>
                        <span>N APOGEE</span>
                        <span>{profile.id}</span>
                    </div>
                    <div className={styles.profileTag}>
                        <span>CNE</span>
                        <span>{profile.et_cne}</span>
                    </div>
                    <div className={styles.profileTag}>
                        <span>CIN</span>
                        <span>{profile.et_cin}</span>
                    </div>

                    <div>
                        <div className={styles.profileTag}>
                            <span>NOM ET PRENOM AR</span>
                            <span>
            {profile.et_f_name_ar} {profile.et_l_name_ar}
          </span>
                        </div>
                        <div className={styles.profileTag}>
                            <span>NOM ET PRENOM FR</span>
                            <span>
            {profile.et_f_name_fr} {profile.et_l_name_fr}
          </span>
                        </div>
                    </div>
                    <div>
                        <div className={styles.profileTag}>
                            <span>DATE DE NAISSANCE</span>
                            <span>
            {profile.et_birthday ? profile.et_birthday.substring(0, 10) : ""}
          </span>
                        </div>
                    </div>
                    <div>
                        <div className={styles.profileTag}>
                            <span>LIEU DE NAISSANCE FR</span>
                            <span>{profile.et_birthday_city}</span>
                        </div>
                    </div>
                    <div>
                        <div className={styles.profileTag}>
                            <span>LIEU DE NAISSANCE AR</span>
                            <span>{profile.et_birthday_city_arb}</span>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="row">
                        {
                            email && email.roseta && (
                                <div className="profile-data-email col-md-12">

                                    <div className="area-email-how">
                                        <p>لتفعيل حسابكم على منصة Rosetta Stone المرجو الضغط على الرابط التالي</p>
                                        <p><a href={"https://login.rosettastone.com/#/ssologin?clientId=a7b79552-157f-4561-a350-32ed990f5d95&targetLanguage=en-US&salesPackageId=salesPackage.1116a1ab-1e8a-427a-9cda-8925b10a179e"} target={"_blank"}>رابط التفعيل</a></p>

                                    </div>
                                </div>
                            )
                        }

                        {
                            email && (
                                <div className="profile-data-email col-md-12">
                                    <div>
                                        <p className="title">تفعيل البريد الإلكتروني الجامعي</p>
                                        <div>
                                            <div className={styles.profileTag + " tag-email"}>
                                                <span>البريد الإلكتروني الخاص بك</span>
                                                <span className="email-input-data">{email.email}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={styles.profileTag+ " tag-email"}>
                                                <span>كلمة السر المؤقتة</span>
                                                <span className="email-input-data">
                                            {showPass ? email.password : "************"}
                                                    <span className="show-pass" onClick={() => setShowPass(!showPass)}>
                                                {!showPass ? "كشف" : "إخفاء"}
                                            </span>
                                        </span>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="area-email-how">
                                        <p>تفعيل البريد الإلكتروني يتم عبر منصة Office على الرابط التالي :</p>
                                        <p><a href={"https://www.office.com/"} target={"_blank"}>https://www.office.com/</a></p>

                                    </div>
                                </div>
                            )
                        }

                        {
                            email && email.moodle && (
                                <div className="profile-data-email col-md-12">
                                    <div>
                                        <p className="title">تفعيل المنصة البيداغوجية Moodle</p>
                                        <div>
                                            <div className={styles.profileTag + " tag-email"}>
                                                <span>اسم المستخدم الخاص بك</span>
                                                <span className="email-input-data">{email.moodle}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={styles.profileTag+ " tag-email"}>
                                                <span>كلمة السر المؤقتة</span>
                                                <span className="email-input-data">
                                            {showPass ? email.password : "************"}
                                                    <span className="show-pass" onClick={() => setShowPass(!showPass)}>
                                                {!showPass ? "كشف" : "إخفاء"}
                                            </span>
                                        </span>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="area-email-how">
                                        <p>يتم تفعيل الحساب عبر الرابط التالي : :</p>
                                        <p><a href={"http://ent.usms.ac.ma/moodle"} target={"_blank"}>http://ent.usms.ac.ma/moodle</a></p>

                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}
